// Modal.js

import React from "react";
import PropTypes from "prop-types";

const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <>
      {/* Overlay */}
      <div className='fixed z-50 flex items-center justify-center bg-gray-500 bg-opacity-50 mx-auto'>
        {/* Modal */}
        <div className='bg-white w-full max-w-lg mx-auto rounded-lg overflow-hidden shadow-lg'>
          {/* Modal header */}
          {title && (
            <div className='bg-gray-200 px-4 py-2 flex justify-between items-center'>
              <h3 className='text-lg font-semibold'>{title}</h3>
              {/* Close button */}
              <button
                className='text-gray-600 hover:text-gray-800 focus:outline-none pl-4'
                onClick={onClose}
              >
                X
              </button>
            </div>
          )}
          {/* Modal content */}
          <div className='p-4'>{children}</div>
        </div>
      </div>
    </>
  );
};

Modal.defaultProps = {
  title: "",
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Modal;
