// CardTable.js

import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "components/Modals/modal"; // Adjust the import path as per your project structure

const CardTable = ({ color }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div
        className={`relative flex flex-col min-w-0 w-full mb-6 shadow-lg rounded ${
          color === "light" ? "bg-white" : "bg-lightBlue-900 text-white"
        }`}
      >
        {/* Render the Modal */}
        <div className='mx-auto'>
          <Modal
            isOpen={modalOpen}
            onClose={handleModalClose}
            title='Inventario de hardware y sofware'
          >
            <p className='text-sm'>Modal Content Goes Here</p>
          </Modal>
        </div>

        {/* Table structure */}
        <table className='items-center text-left w-full bg-transparent border-collapse'>
          <thead>
            <tr>
              <th
                className={`px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700"
                }`}
              >
                Equipo
              </th>
              <th
                className={`px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700"
                }`}
              >
                CPU
              </th>
              <th
                className={`px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700"
                }`}
              >
                MEMORIA RAM
              </th>
              <th
                className={`px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700"
                }`}
              >
                DISCO DURO
              </th>
              <th
                className={`px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                  color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700"
                }`}
              >
                Info
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                Argon Design System
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $2,500 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-orange-500 mr-2'></i> pending
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>60%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-red-200'>
                      <div
                        style={{ width: "60%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                Angular Now UI Kit PRO
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $1,800 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-emerald-500 mr-2'></i>{" "}
                completed
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>100%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-emerald-200'>
                      <div
                        style={{ width: "100%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-emerald-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                Black Dashboard Sketch
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $3,150 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-red-500 mr-2'></i> delayed
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>73%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-red-200'>
                      <div
                        style={{ width: "73%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            <tr>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                React Material Dashboard
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                $4,400 USD
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <i className='fas fa-circle text-teal-500 mr-2'></i> on schedule
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                <div className='flex items-center'>
                  <span className='mr-2'>90%</span>
                  <div className='relative w-full'>
                    <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                      <div
                        style={{ width: "90%" }}
                        className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'
                      ></div>
                    </div>
                  </div>
                </div>
              </td>
              <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                {/* Modal trigger button */}
                <button
                  className='text-blueGray-500 hover:text-blueGray-800 cursor-pointer'
                  onClick={handleModalOpen}
                >
                  <i className='fas fa-info-circle'></i>
                </button>
              </td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </>
  );
};

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};

export default CardTable;
